




















































import isMobile from "@/assets/utils/isMobile"

import { UseFields } from "piramis-base-components/src/components/Pi/index"

import { Component, Mixins, Prop } from "vue-property-decorator"
import { snakeCase } from 'lodash'

@Component({
  data() {
    return {
      isMobile
    }
  }
})
export default class ExampleView extends Mixins<UseFields>(UseFields) {
  @Prop() filterKey!: string

  @Prop() textExamples!: Array<string>

  @Prop() imgExamples!: Array<string>

  getViewText(key: string): string {
    return this.$t(`${ snakeCase(key) }_filter_example_view_text`).toString()
  }
}
